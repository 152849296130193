.text-success {
  color: #41b146 !important;
}

.text-danger {
  color: #c74141 !important;
}

.text-warning {
  color: #e5a275 !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-underline {
  text-decoration: underline !important;
}

@import 'colors';

.ng-select {
  & {
    margin-bottom: 22px !important;
    padding-bottom: 0 !important;
  }

  .ng-select-container {
    border: solid 0.5px #dedede !important;
    border-radius: 2px !important;
    min-height: 43px !important;
    background-color: #fff;
    padding: 0 5px 0 12px !important;
    padding-top: 4px !important;

    .ng-placeholder {
      left: 14px !important;
      font-size: 14px;
      color: #292929;
      text-overflow: ellipsis;
      position: inherit;
      overflow: hidden;
    }

    /*&.ng-has-value {
      .ng-value-container, .ng-place-holder {
        margin-left: 12px;
      }
    }*/
  }

  &.ng-invalid.ng-select-error .ng-placeholder {
    color: #c74141 !important;
  }

  .ng-value-container {
    padding: 0.7em 0;
    padding-bottom: 0 !important;
    min-width: 180px;
  }

  .ng-dropdown-panel.ng-select-bottom {
    top: inherit !important;
  }

  &.ng-select-focused .ng-select-container .ng-placeholder {
    color: #40ae45 !important;
    //top: 7px;
  }

  & .ng-select-container::after {
    border: none !important;
  }

  &.ng-select-opened .ng-select-container {
    padding-top: 4px !important;
  }

  &.ng-select-single .ng-select-container .ng-arrow-wrapper {
    bottom: 18px !important;
    right: 10px !important;
  }

  & .ng-arrow-wrapper .ng-arrow,
  &.ng-select-focused .ng-arrow-wrapper .ng-arrow {
    //color: #40ae45 !important;
    color: rgba(0, 0, 0, 0.54) !important;
  }

  &.ng-select-single .ng-select-container .ng-value-container .ng-input {
    //left: inherit !important;
  }

  .ng-select-container:not(.ng-has-value), &:not(.ng-select-edit-focused) {
    .ng-value-container {
      border-top: 0;
    }
  }

  .ng-select-container.ng-has-value {
    //padding-top: 14px;
    .ng-value-container {
      border-top: 0.84375em solid transparent;
    }
  }

  &.ng-select-opened .ng-select-container {
    .ng-value-container {
      border-top: 0.84375em solid transparent;
    }
  }

  &.ng-select.ng-select-focused.ng-select-edit-focused .ng-value-label {
    color: transparent;
  }

  &.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    bottom: 10px !important;
    right: 0 !important;
  }
}

ng-dropdown-panel.ng-dropdown-panel {
  z-index: 99999;

  .ng-dropdown-panel-items {
    .ng-option.ng-option-selected {
      color: #40ae45 !important;
    }

    .ng-option {
      line-height: 1.2em;
      padding: 16px;
    }
  }
}

.mat-raised-button {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-decoration: none !important;
}

.mat-dark {
  background-color: #191919 !important;
  color: #f9f9f9 !important;
}

.mat-grey {
  background-color: #676767 !important;
  color: #f9f9f9 !important;
}


.mat-button-toggle {
  color: #191919;
}

.mat-button-toggle-checked {
  background-color: #41b146;
  color: #f9f9f9;
}

ngb-modal-window {

  .modal-content {
    border-radius: 2px;

    .close {
      position: absolute;
      right: -30px;
      opacity: 1;
      color: #ffffff;
      font-size: 32px;
      line-height: 1rem;
      font-weight: 500;
    }

    .modal-body {
      padding: 38px 42px;
    }

    .modal-header, .modal-footer {
      padding: 42px;
      border: none;
    }

    .modal-header {
      padding-bottom: 0;

      .close {
        color: #ffffff !important;
        top: 0 !important;
      }
    }

    .modal-footer {
      padding-top: 0;
    }
  }

  .modal-title {
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.32px;
    color: #292929;
    text-align: center;
  }

  &.calendly-popup {

    .modal-content .modal-header .close {
      right: 0 !important;
      top: 40px !important;
      z-index: 1;
    }


    .modal-dialog, .modal-content, .modal-body {
      height: 100% !important;
    }

    .modal-content {
      background-color: transparent;
      border: none;
    }
  }


}


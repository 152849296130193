.control-label {
  font-size: 14px;
  letter-spacing: 0.32px;
  font-weight: 500;
}

.form-control {
  border-radius: 2px !important;
  border: 0.5px solid #dedede !important;
  color: #292929;

  &:focus {
    border: 2px solid #dadfe6 !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

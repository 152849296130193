.mat-form-field {
  display: block !important;
  border-radius: 2px;
  border: solid 0.5px #dedede;
  background-color: #ffffff;
  letter-spacing: 0.28px;
  color: #292929;
  //padding: 7px 12px 8px;
  padding: 0;
  font-family: 'Poppins Regular', sans-serif !important;
  font-size: 1rem !important;

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-underline {
    bottom: 1px;
    height: 0 !important;
  }

  .mat-form-field-flex {
    padding: 0 12px;
  }

  .mat-input-wrapper {
    padding: 0;
  }

  .mat-input-infix {
    padding: 4px 0;
    margin-top: 6px;
  }

  .mat-input-underline {
    bottom: 1px;
    height: 0;
  }

  .mat-form-field-empty {
    top: 10px;
  }

  &.mat-focused .mat-form-field-label, &.ng-touched .mat-form-field-label {
    &.mat-form-field-empty {
      top: 1.28125em !important;
    }
  }

  margin-bottom: 9px;

  &.mat-form-field-disabled {
    background-color: #f3f3f3;

    .mat-form-field-suffix {
      color: #aaa;
    }
  }

  .mat-form-field-suffix {
    top: 0 !important;
  }

  .mat-input-element[_ngcontent-c19]:disabled {
    color: rgba(0, 0, 0, 0.4) !important;
  }

  .mat-input-suffix {
    position: relative;
    top: -8px;
    margin-left: 5px;
  }

}

.mat-select {
  .mat-select-arrow-wrapper .mat-select-arrow {
    margin-top: -10px;
    border-left: 5.5px solid transparent;
    border-right: 5.5px solid transparent;
    border-top: 7px solid;
  }
}

.mat-focused {
  .mat-select {
    .mat-select-arrow-wrapper .mat-select-arrow {
      color: #47834a;
    }
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #47834a;
}

.mat-form-field {
  margin-bottom: 1.4rem !important;
}

.mat-error {
  margin-top: 1.1rem !important;
}

/*

mat-form-field-legacy-typography({
  //top: $infix-margin-top + $infix-padding;
});
*/
.mat-form-field-label-wrapper {
  top: -0.70em;
  padding-top: 0.70em;
}

.btn {
  text-transform: none !important;
  font-size: 13px !important;
  cursor: pointer !important;
  border: none;
  width: 195px;
  max-width: 100%;
  letter-spacing: 0.3px;
  border-radius: 2px !important;
  line-height: 1 !important;
  padding: 12px !important;
  text-decoration: none !important;
}

a.btn-link:hover {
  text-decoration: underline;
}

.btn-sm {
  padding: 0.625rem 1.5rem !important;
  width: auto;
}

.btn-group .btn {
  width: auto;
}

.btn-block {
  width: 100%;
}

.btn-link {
  font-family: 'Poppins Light', sans-serif !important;
  padding: 0 !important;
  text-align: inherit;
  color: #171717 !important;
  width: auto !important;
}

.btn-circle {
  padding: 0 !important;
  border-radius: 50% !important;
  width: 42px;
  height: 42px;
  font-size: 12px !important;
  min-width: inherit;
}

.btn-round {
  width: 118px;
  border-radius: 23.5px !important;
  border: 2px solid transparent !important;
}

.btn-outline-success {
  color: #41b146 !important;
  border: 2px solid #41b146 !important;

  &:hover {
    color: #ffffff !important;
  }
}

.btn-outline-danger {
  color: #c74141 !important;
  border: 2px solid #c74141 !important;

  &:hover {
    color: #ffffff !important;
  }
}

.btn-outline-secondary {
  color: #6c757d !important;
  border: 2px solid #6c757d !important;

  &:hover {
    color: #ffffff !important;
  }
}

.btn-success {
  background-color: #41b146 !important;
  color: #fff !important;

  &:hover {
    background-color: rgba(#41b146, 0.8) !important;
  }
}

.btn-danger {
  background-color: #c74141 !important;

  &:not(:disabled):hover {
    background-color: rgba(#c74141, 0.8) !important;
  }
}

.btn-dark {
  background-color: #171717 !important;
  color: #fff !important;
}

.btn-secondary {
  background-color: #7d7d7d !important;

  &:hover {
    background-color: rgba(#7d7d7d, 0.8) !important;
  }
}

.btn-outline-danger,
.btn-outline-success {
  padding-bottom: 11px;
  padding-top: 11px;
}

.app-clear-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  width: auto;
  line-height: 20px;
  font-weight: 600;
  padding: 0.625rem;
  color: rgb(34, 34, 34);
  margin: 0;
  border-radius: 8px;
  outline: none;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s,
  transform 0.25s ease 0s;
  border-style: none;
  background: transparent;
  text-decoration: underline;

  &:disabled,
  &:hover:disabled {
    cursor: not-allowed;
    opacity: 1;
    color: rgb(221, 221, 221);
    background: transparent;
  }

  &:hover {
    color: rgb(0, 0, 0);
    background: rgb(247, 247, 247);
  }
}

.app-save-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  width: auto;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  color: rgb(255, 255, 255);
  margin: 0;
  text-decoration: none;
  border-radius: 8px;
  outline: none;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s,
  transform 0.1s ease 0s;
  border-style: none;
  background: rgb(34, 34, 34);

  &:hover {
    background: rgb(0, 0, 0);
  }
}

.menu-icon {
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  color: #f0f0f0 !important;
}

.my-tasks {
  background-image: url('/assets/images/clipboard-check.svg');
  background-size: auto 80%;
}

.menu-icon.dashboard {
  background-image: url('/assets/images/dashboard.svg');
  background-size: auto 70%;
}

.my-company {
  background-image: url('/assets/images/company.svg');
  background-size: auto 80%;
}

.my-suppliers {
  background-image: url('/assets/images/helmet.svg');
  background-size: auto 75%;
}

.my-customers {
  background-image: url('/assets/images/businessman.svg');
  background-size: auto 75%;
}

.pos-issued {
  background-image: url('/assets/images/upload.svg');
  background-size: auto 75%;
}

.pos-received {
  background-image: url('/assets/images/download.svg');
  background-size: auto 75%;
}

.ion-quickbooks-icon {
  background: url('/assets/images/qb-logo-b&w.png') no-repeat center center;
  background-size: auto 100%;
}

.products {
  background-image: url('/assets/images/product-box.svg');
  background-size: auto 75%;
}

.archived-pos {
  background-image: url('/assets/images/box.svg');
  background-size: auto 75%;
}

.deleted-pos {
  background-image: url('/assets/images/trash-light.svg');
  background-size: auto 75%;
}

.main-logo {
  height: 100px;
  width: 100% !important;
  margin: 0 !important;
  overflow: hidden;
  // background: url("/assets/images/ion_logo_tiny.png") no-repeat 3rem center !important;
  display: block;

  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px 0px 10px 0px;
  background-origin: content-box !important;
}

.tour-confirm {
  width: fit-content !important;
  padding: 4px 10px !important;
  border: 1px solid #BBB !important;
  display: inline-block;
}

.help-videos-link {
  border: 1px solid black;
  border-radius: 10px;
  padding: 4px 9px;
  text-transform: uppercase;
  line-height: 18px !important;
  display: flex;
  text-decoration: none !important;

  i {
    font-size: 18px;
    display: inline;
    height: max-content;
  }
}

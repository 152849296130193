@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3cgmb0');
  src:  url('fonts/icomoon.eot?3cgmb0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3cgmb0') format('truetype'),
    url('fonts/icomoon.woff?3cgmb0') format('woff'),
    url('fonts/icomoon.svg?3cgmb0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ion-"], [class*=" ion-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ion-alert-icon:before {
  content: "\e902";
}
.ion-bell-icon:before {
  content: "\e903";
}
.ion-bell-active-icon:before {
  content: "\e904";
}
.ion-box-icon:before {
  content: "\e905";
}
.ion-box-active-icon:before {
  content: "\e906";
}
.ion-businessman-icon:before {
  content: "\e907";
}
.ion-calendar-icon:before {
  content: "\e908";
}
.ion-clip-active-icon:before {
  content: "\e909";
}
.ion-clipboard-icon:before {
  content: "\e90a";
}
.ion-clipboard-check-icon:before {
  content: "\e90b";
}
.ion-company-icon:before {
  content: "\e90c";
}
.ion-dashboard-icon:before {
  content: "\e90d";
}
.ion-download-icon:before {
  content: "\e90e";
}
.ion-file-icon:before {
  content: "\e90f";
}
.ion-helmet-icon:before {
  content: "\e910";
}
.ion-my-customers-icon:before {
  content: "\e911";
}
.ion-my-suppliers-icon:before {
  content: "\e912";
}
.ion-open-book-icon:before {
  content: "\e913";
}
.ion-pos-issued-icon:before {
  content: "\e914";
}
.ion-pos-reseived-icon:before {
  content: "\e915";
}
.ion-product-box-icon:before {
  content: "\e916";
}
.ion-trash-light-icon:before {
  content: "\e917";
}
.ion-upload-icon:before {
  content: "\e918";
}
.ion-user-icon:before {
  content: "\e919";
}
.ion-file-checked-icon:before {
  content: "\e900";
}
.ion-file-cancel-icon:before {
  content: "\e901";
}

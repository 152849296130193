.toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.toggle-switch {
  position: relative;
  width: 60px;
  height: 34px;
  margin-left: 12px;
  
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
    
    &:before {
      content: "";
      position: absolute;
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }
  
  input:checked + .slider {
    background-color: #007bff;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
}

.toggle-label {
  font-size: 14px;
  font-weight: 500;
  color: #495057;
  margin-right: 8px;
}

@import '~@nebular/theme/styles/globals';
@import '~nebular-icons/css/nebular-icons.css';

@import 'themes';

// loading progress bar theme
@import './pace.theme';

// ng-select default style
@import '~@ng-select/ng-select/themes/material.theme.css';

@import '@angular/cdk/overlay-prebuilt.css';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  //@include nb-auth-global();

  // loading progress bar
  @include ngx-pace-theme();
}


html, body {
  outline: none;
}

button:focus {
  outline: none;
}

a {
  cursor: pointer !important;
}

.main-content {
  min-height: 100%;
  max-width: 100%;
}

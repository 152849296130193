.smartour-slot {
  position: relative;
  background: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 1rem;
}

.smartour-slot_top {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px)
}

.smartour-slot_top:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
}

.smartour-slot_bottom {
  -webkit-transform: translateY(10px);
  transform: translateY(10px)
}

.smartour-slot_bottom:after {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
}

.smartour-slot_right {
  -webkit-transform: translateX(10px);
  transform: translateX(10px)
}

.smartour-slot_right::after {
  content: '';
  position: absolute;
  left: -5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-bottom: 5px solid transparent;
  border-right: 5px solid #fff;
  border-top: 5px solid transparent;
}

.smartour-slot_left {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px)
}

.smartour-slot_left::after {
  content: '';
  position: absolute;
  right: -5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-bottom: 5px solid transparent;
  border-left: 5px solid #fff;
  border-top: 5px solid transparent;
}

// import Nebular Theme System and the default theme
@import '~@nebular/theme/styles/theming';
@import '~@nebular/theme/styles/themes/default';
@import '~nebular-icons/scss/nebular-icons';

// @nebular theming framework
//@import '~@nebular/theme/styles/theming';
// @nebular out of the box themes
//@import '~@nebular/theme/styles/themes';

// or @import '~@nebular/auth/styles/{theme-name}';
//@import '~@nebular/auth/styles/all';

@import './everest';

// which themes you what to enable (empty to enable all)
// $nb-enabled-themes: (default, cosmic);
//$nb-enabled-themes: (default);

$nb-themes: nb-register-theme(
    (
      // app wise variables for each theme
      // sidebar-header-gap: 2rem,
      // sidebar-header-height: initial,
      layout-content-width: 1400px,
      //font-main: Poppins !important,
      //font-secondary: Poppins !important,
      font-family-primary: Poppins,
      font-family-secondary: Poppins,
      scrollbar-width: 0.6rem,
      menu-text-color: #f0f0f0,
      layout-background-color: #fff,
      user-picture-box-background-color: #fff,
      context-menu-border-color: #d3d3d3,
      context-menu-border-width: 1px,
      context-menu-border-style: solid,
      context-menu-background-color: #fff,
    ),
    default,
    default
);

// $nb-themes: nb-register-theme((
//   // app wise variables for each theme
//   sidebar-header-gap: 2rem,
//   sidebar-header-height: initial,
//   layout-content-width: 1400px,

//   font-main: Roboto,
//   font-secondary: Exo,
// ), cosmic, cosmic);

$mini-form-color: #fefefe;

.lasgro-title {
  // text-align: center;
  padding: 20px;
  //font-family: Arial, Helvetica, sans-serif !important;
}

.backdrop {
  // z-index: 9999999
}

$lasgroSideMenuBG: transparent;

@media screen and (max-width: 767px) {
  .hidden-sm {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

*:not(i):not(.dx-*):not(.dx-navigate-button):not(.mat-icon) {
  font-family: Poppins, Arial, sans-serif !important;
}

.pull-right {
  float: right !important;
}

.muted {
  color: #9d9d9d;
}

.draggable {
  cursor: grab;
}

.gu-unselectable, .gu-transit {
  .draggable {
    cursor: grabbing !important;
  }

}

.clickable {
  cursor: pointer;
}

.white-bg {
  background: white !important;
}

.btn.btn-md {
  font-size: 14px !important;
}

.btn.btn-light {
  color: #333333 !important;
}

.nb-theme-default nb-layout .layout nb-layout-header + .layout-container {
  padding-top: 3.5rem !important;
}

.nb-theme-default nb-layout .layout nb-layout-header nav {
  background: #f1f1f1 !important;
  height: 3.5rem !important;
  box-shadow: none !important;
}

.nb-theme-default .po-detail nb-layout-header nav {
  background-color: white !important;
  box-shadow: none !important;
}

.nb-theme-default nb-sidebar {
  z-index: 6;

  .scrollable {
    background: #1a1c1e;
    padding-top: 0 !important;
  }
}

.nb-theme-default nb-menu .menu-item a {
  background: $lasgroSideMenuBG;
  //color: #f0f0f0;
  //font-family: Arial, Helvetica, sans-serif !important;
  // border-radius: 0 !important;
  border-style: none !important;
  font-size: 13px !important;
  padding: 0.4rem 1rem 0.4rem 1rem !important;
  font-weight: 500 !important;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 7px;
  border-radius: 5px !important;
}

.nb-theme-default nb-menu .menu-item:first-child a[href='#'] {
  margin-top: 10px;
}

.nb-theme-default nb-menu .menu-item a[href='#'] {
  color: #40ae45 !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  background: #292929 !important;
  padding-left: 1.5rem !important;
}

.nb-theme-default nb-menu .menu-item .menu-item:first-child {
  border-top: none !important;
}

.nb-theme-default nb-menu .menu-item .menu-items {
  background: transparent !important;
  padding: 0 !important;
}

.nb-theme-default nb-menu .menu-item a:hover:not([href="#"]),
.nb-theme-default nb-menu .menu-item a.active:not([href="#"]) {
  background: #323332 !important;
  // background: #40ae45 !important;
  font-size: 13px !important;
  color: #f0f0f0 !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  // border-left: 3px solid #40ae45 !important;
  // padding: 0.4rem 1rem 0.4rem calc(1.5rem - 3px) !important;

  .chevron {
    color: #f0f0f0 !important;
  }
}

.nb-theme-default nb-menu .menu-item a[href='#']:hover,
.nb-theme-default nb-menu .menu-item a[href='#'].active {
  background: #292929 !important;
  color: #40ae45 !important;
  padding-top: 30px !important;
  padding-bottom: 20px !important;
  padding-left: 1.5rem !important;
}

.nb-theme-default nb-menu .menu-item a[href="#"] > nb-icon[ng-reflect-icon="chevron-down-outline"] + span.badge {
  display: none;
}

.nb-theme-default nb-icon {
  width: 24px;
}

/* .nb-theme-default nb-menu > .menu-items > .menu-item:first-child a:hover,
.nb-theme-default nb-menu > .menu-items > .menu-item:first-child a.active {
  border: none !important;
} */

.action-link {
  color: #40ae45 !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
}

.action-link:hover, .action-link:focus {
  color: #6ace6f !important;
}

.nb-theme-default .text-info {
  color: #40ae45 !important;
}

.pill-btn-container {
  padding: 0 0.125rem 0.5rem !important;
}

.pill-btn {
  font-size: 13px !important;
  border-color: #40ae45 !important;
  color: #40ae45 !important;
  padding: 0.8rem 1rem !important;
}

.pill-btn:hover {
  border-color: #6ace6f !important;
  color: #6ace6f !important;
}

.search-label {
  font-size: 14px;
}

.main-btn {
  background: #292929 !important;
  color: white !important;
}

i.table-icon {
  width: 22px;
  height: 22px;
  font-size: 22px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 80%;
}

i.table-icon.checklist {
  background-image: url('/assets/images/clipboard-check-active.svg');
}

i.table-icon.archive {
  background-image: url('/assets/images/box-active.svg');
  width: 20px;
  height: 20px;
}

i.table-icon.files {
  background-image: url('/assets/images/clip-active.svg');
}

i.table-icon.trash {
  background-image: url('/assets/images/trash.svg');
}

i.table-icon.icon-alert {
  background-image: url('/assets/images/alert.svg');
  width: 18px;
  height: 18px;
}

.nb-theme-default .sidebar-footer {
  .navigation {

    i {
      font-size: 1.15rem;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.6);
      width: 32px;
      height: 32px;
      padding-left: 10px;
      padding-top: 8px;
      color: #f0f0f0 !important;
    }
  }
}

// border bottom of the active tab
.nb-theme-default nb-route-tabset ul li a::before {
  background: #444 !important;
}

.nb-theme-default nb-route-tabset ul {
  background: #f4f4f4;
}

.menu-item.menu-group.ng-star-inserted {
  background-color: $lasgroSideMenuBG;
}

.nb-theme-default nb-menu {
  .menu-items, .menu-item {
    background-color: $lasgroSideMenuBG !important;

    .menu-item {
      border-bottom: 0 !important;
    }

    .menu-group {
      // padding: 0.5rem 1.25rem !important;
      padding: 0 !important;
      margin: 10px 0 !important;
      border-top: 1px solid #353535;

      & + .menu-group {
        border-top: none !important;
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
}

.nb-theme-default nb-menu {
  .menu-item .menu-item {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.dx-widget {
  font-family: Poppins, Arial, sans-serif !important;
}

dx-data-grid.no-borders .dx-datagrid .dx-column-lines > td {
  border: none;
}

.dx-datagrid-headers {
  color: #292929 !important;
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.dx-datagrid .dx-row > td {
  padding: 7px 5px !important;
}

.dx-datagrid-rowsview {
  border-top: none !important;
}

.dx-datagrid-rowsview .dx-row > td:not(.text-success):not(.text-danger) {
  color: #5f5f5f !important;
}

.dx-datagrid-rowsview .dx-row > td.show-borders {
  border: 1px solid #dddddd;
}

.dx-datagrid-rowsview .dx-row > td.warn {
  background: repeating-linear-gradient(-55deg, #ffffff, #ffffff 10px, #fffbe9 10px, #fff7cf 20px);
}

.dx-datagrid-rowsview .dx-row > td {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

.dx-pager {
  display: flex;
  margin-top: 20px;
  border: none !important;

  .dx-pages {
    float: none;
    margin: auto;
    font-size: 12px;
    font-weight: 500;

    .dx-selection {
      background-color: #40ae45 !important;
      color: #fff;
    }

    .dx-page {
      padding: 5px 9px 6px;
    }

    .dx-prev-button, .dx-next-button {
      font-size: 14px;
      text-align: center;
      line-height: 15px;
    }
  }
}

.po-detail-container .mat-tab-header {
  margin-top: 0 !important;
}

.lasgro-container {
  margin: -20px !important;
}

h4.product-title {
  color: #949494;
  width: 100%;

  i {
    margin-right: 20%;
  }
}

.ev-product-overview {
  padding: 20px;
}

// used in the details sections that show up in the right side of the grid
.left-border {
  border-left: 1px solid #dddddd;
  padding-right: 30px;
}

.ev-clickable {
  cursor: pointer;
}

.ev-form-control {
  text-align: right !important;
  padding-top: 15px;
}

.everest-mini-form {
  background-color: #fafafa;
  padding: 20px;
  margin-left: -15px;
  margin-right: -15px;
}

.label-option {
  span {
    font-size: 11px;
    font-weight: bold;
  }
}

.mat-tab-label {
  min-width: 20px !important;
  flex: 1 !important;
  padding: 0 !important;
}

::-webkit-scrollbar {
  height: 0;
  width: 6px !important;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  border-radius: 0px !important;
  // background: #e3e3e3 !important;
  background: transparent !important;
  background: rgba(255,255,255,0.5) !important;

}

::-webkit-scrollbar-thumb {
  // background: #ccc;
  // border-radius: 10px;
  // background: #f2f2f2 !important;
  // background: #f2f2f2 !important;
  cursor: pointer !important;
  border-radius: 0 !important;
  // box-shadow: inset 0 0 5px grey !important;
  box-shadow: none !important;
  background: rgba(0,0,0,0.5) !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #cecece;
}

.nb-theme-default .text-success {
  color: #41b146 !important;
}

.go-back {
  font-weight: bold;
  font-size: 1rem !important;
}

.btn-success {
  background-color: #40ae45 !important;
}



.ribbon {
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 3px solid #1eb5ff;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  color: white;
  background-color: #d04d4d;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

/* top left*/
.ribbon-top-left {
  top: -3px;
  left: -3px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -27px;
  top: 0px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -3px;
  right: -3px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -22px;
  top: 30px;
  transform: rotate(45deg);
}

.wdp-ribbon{
  display: inline-block;
    padding: 2px 15px;
  position: absolute;
    right: 0px;
    top: 20px;
    line-height: 24px;
  height:24px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
  border-radius: 0;
    text-shadow: none;
    font-weight: normal;
    background-color: #1eb5ff !important;
}

.wdp-ribbon-two:before, .wdp-ribbon-two:before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -14px;
    top: 0;
    border: 9px solid transparent;
    border-width: 14px 8px;
    border-right-color: #1eb5ff;
}
.wdp-ribbon-two:before {
    border-color: #1eb5ff;
    border-left-color: transparent!important;
    left: -9px;
}
.wdp-ribbon-four{
  right: 10px;
    top: 10px;
    line-height: 60px;
    height: 60px;
}
.wdp-ribbon-five{
  background: #1eb5ff;
  right: 10px;
    top: 10px;
  padding: 0px;
  height: 60px;
  width: 60px;
  text-align: center;
  vertical-align: middle;
  line-height: 60px;
  border-radius:50%;
}
.wdp-ribbon-six{
  background: none !important;
    position: relative;
    box-sizing: border-box;
    position: absolute;
    width: 65px;
    height: 65px;
  top:0px;
  right:0px;
  padding:0px;
  overflow: hidden;
}
.wdp-ribbon-inner-wrap{
    -ms-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
.wdp-ribbon-border{
    width: 0;
    height: 0;
    border-right: 65px solid #1eb5ff;
    border-bottom: 65px solid transparent;
    z-index: 12;
  position:relative;
  top:-20px;
}
.wdp-ribbon-text {
    font-size: 13px;
    font-weight: bold;
    line-height: 13px;
    position: absolute;
    z-index: 14;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 15px;
    left: -5px;
    width: 91px;
    text-align: center;
}

.modal {
  // z-index: 1999;
}

/////// TIMELINE /////////

$tl-color-red:         #d81e05;
$tl-color-grey:        #dedede;

.timeline{
  display: flex;
  overflow-x: hidden;
  margin: 0px;
  min-height: 300px;
}

.timeline:after{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 299px;
  height: 2px;
  border: 2px solid $tl-color-grey;
}

.timeline-container{
  display: flex;
  transition: .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  // min-width: 750px;
  min-width: calc(100vw - 17rem);
  overflow-x: hidden;
  overflow-y: hidden;
}

.timeline-card{
  flex: 0 0 auto;

  position: relative;
  padding: 20px;
    
  width: 320px;
  height: 230px;
  
  background: #fff;
  border: 2px solid $tl-color-grey;

  cursor: pointer;
  transition: .5s all;
}

.dual .timeline-card:not(:first-child) {
    margin-left: -135px;
}

.dual .timeline-card:nth-of-type(even) {
    margin-top: 330px;
}

.single .timeline-card:not(:first-child) {
    margin-left: 50px;
}

.single .timeline-card:nth-of-type(even) {
    margin-top: 0;
}

.timeline-card-svg path{
  fill: $tl-color-grey;
}

.timeline-card-svg:nth-of-type(odd),
.timeline-card-svg:nth-of-type(even) {
  position: absolute;
  width: 13px;
  height: 49px;
  left: 50%;
  bottom: -63px;
  
  transform: translate(-50%);
}

.dual .timeline-card:nth-of-type(even) .timeline-card-svg {
  top: -12px;
  margin-top: -49px;
  transform: scaleY(-1) translate(-50%);
}

.timeline-nav{
  position: absolute;
  margin-top: 300px;
  z-index: 10;
  // width: 100%;
  width: calc(100vw - 17rem);
}

.timeline-nav-button{
  position: absolute;
  width: 48px;
  height: 48px;
  cursor: pointer;

  opacity: .75;
  box-shadow: 0 8px 20px 0 rgba(0,0,0,0.1);
  border-radius: 100%;
  background: #fff;
  
  transform: translate(-50%, -50%);
  transform-origin: center;
  transition: 0.3s ease;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-nav-button:hover{
  width:60px;
  height:60px;
}

.timeline-nav-button:after{
  content: "";
  position:absolute;
  
  height: 2px;
  width: 10px;
  
  background:$tl-color-red;
}

.timeline-nav-button:before{
  content: "";
  position:absolute;
  
  margin-left:-1px;
  height: 8px;
  width: 8px;
  border-style: solid;
  border-color: $tl-color-red;
  border-width: 0px 2px 2px 0px;
  
  transform: rotate(135deg);
}

.mat-tab-link {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: Poppins, Arial, sans-serif !important;
  font-size: 14px;
  font-weight: 500;

  &.mat-tab-label-active {
    opacity: 1;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.mat-tab-labels {
  display: inline-flex !important;
  //white-space: nowrap;
}

.mat-tab-label-container {
  background-color: #d8d8d8;
}

.mat-tab-label {
  padding: 0 60px !important;
  flex: auto !important;
  border-right: solid 1px #fff;

  &.mat-tab-label-active {
    background-color: #ffffff !important;
    opacity: 1;
  }
}

.mat-tab-header .mat-ink-bar {
  height: 4px;
  top: 0 !important;
  flex: auto;
}

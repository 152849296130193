.archived-row {
  background-color: rgba(108, 117, 125, 0.05) !important;
}

.status-badge {
  padding: 6px 12px;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  
  &.archived {
    background-color: #e9ecef;
    color: #6c757d;
  }
  
  &.pending {
    background-color: #fff3cd;
    color: #856404;
  }
  
  &.rejected {
    background-color: #f8d7da;
    color: #721c24;
  }

  mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    line-height: 18px;
  }
}
